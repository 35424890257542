
export default {
  emits: ['close'],
  methods: {
    goToCredits() {
      this.$emit('close');
      this.$router.push(this.localePath({ name: 'credits' }));
    },
  },
};
